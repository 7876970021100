var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Mã hồ sơ","label-for":"input-1"}},[_c('validation-provider',{attrs:{"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"trim-input",rawName:"v-trim-input"}],ref:"hoSoId",attrs:{"id":"input-1","type":"text","disabled":"","placeholder":"Nhập mã hồ sơ","required":""},on:{"input":function($event){_vm.dataForm.hoSoId = _vm.dataInput.hoSoId}},model:{value:(_vm.dataInput.hoSoId),callback:function ($$v) {_vm.$set(_vm.dataInput, "hoSoId", $$v)},expression:"dataInput.hoSoId"}}),_c('div',{staticClass:"label-noti-validate"},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('div',{staticClass:"label-noti-validate"},[_vm._v(" "+_vm._s(_vm.errorTrungMa)+" ")])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Phí hồ sơ (VNĐ)","label-for":"phiInput"}},[_c('validation-provider',{key:"phiInput",attrs:{"rules":{ required: true },"name":"phiInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"phiInput",class:{required: errors.length > 0},attrs:{"id":"phiInput","type":"text","placeholder":"Nhập phí hồ sơ"},on:{"input":function($event){_vm.dataForm.phiHoSo = _vm.dataInput.phiHoSo}},model:{value:(_vm.dataInput.phiHoSo),callback:function ($$v) {_vm.$set(_vm.dataInput, "phiHoSo", $$v)},expression:"dataInput.phiHoSo"}}),_c('div',{staticClass:"label-noti-validate"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-1","label":"Trạng thái phí hồ sơ","label-for":"input-1"}},[_c('b-form-checkbox',{attrs:{"switch":""},on:{"input":function($event){_vm.dataForm.phiHs_DaNop = _vm.dataInput.phiHs_DaNop}},model:{value:(_vm.dataInput.phiHs_DaNop),callback:function ($$v) {_vm.$set(_vm.dataInput, "phiHs_DaNop", $$v)},expression:"dataInput.phiHs_DaNop"}})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Lệ phí hồ sơ (VNĐ)","label-for":"lePhiInput"}},[_c('validation-provider',{key:"lePhiInput",attrs:{"rules":{ required: true },"name":"lePhiInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"lePhiInput",class:{required: errors.length > 0},attrs:{"id":"lePhiInput","type":"text","placeholder":"Nhập lệ phí hồ sơ"},on:{"input":function($event){_vm.dataForm.lePhiHoSo = _vm.dataInput.lePhiHoSo}},model:{value:(_vm.dataInput.lePhiHoSo),callback:function ($$v) {_vm.$set(_vm.dataInput, "lePhiHoSo", $$v)},expression:"dataInput.lePhiHoSo"}}),_c('div',{staticClass:"label-noti-validate"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"id":"input-group-1","label":"Trạng thái lệ phí hồ sơ","label-for":"input-1"}},[_c('b-form-checkbox',{attrs:{"switch":""},on:{"input":function($event){_vm.dataForm.lePhi_DaNop = _vm.dataInput.lePhi_DaNop}},model:{value:(_vm.dataInput.lePhi_DaNop),callback:function ($$v) {_vm.$set(_vm.dataInput, "lePhi_DaNop", $$v)},expression:"dataInput.lePhi_DaNop"}})],1),(_vm.formMode === 'NhanThuPhi')?_c('div',[_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Số điện thoại","label-for":"soDienThoai"}},[_c('validation-provider',{key:"soDienThoai",attrs:{"rules":'required|phone',"name":"soDienThoai"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"block-e",rawName:"v-block-e"}],ref:"soDienThoai",class:{required: errors.length > 0},attrs:{"id":"soDienThoai","type":"number","placeholder":"Nhập số điện thoại"},on:{"input":function($event){_vm.dataForm.soDienThoai = _vm.dataInput.soDienThoai}},model:{value:(_vm.dataInput.soDienThoai),callback:function ($$v) {_vm.$set(_vm.dataInput, "soDienThoai", $$v)},expression:"dataInput.soDienThoai"}}),_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,1054816959)})],1)],1):(_vm.formMode === 'ChoThuPhi')?_c('div',[_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Người nộp","label-for":"nguoiNop"}},[_c('validation-provider',{key:"nguoiNop",attrs:{"rules":{ required: true },"name":"nguoiNop"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"trim-input",rawName:"v-trim-input"}],ref:"nguoiNop",class:{required: errors.length > 0},attrs:{"id":"nguoiNop","type":"text","placeholder":"Nhập người nộp"},on:{"input":function($event){_vm.dataForm.nguoiNop = _vm.dataInput.nguoiNop}},model:{value:(_vm.dataInput.nguoiNop),callback:function ($$v) {_vm.$set(_vm.dataInput, "nguoiNop", $$v)},expression:"dataInput.nguoiNop"}}),_c('div',{staticClass:"label-noti-validate"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Mã hồ sơ"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        :rules="{ required: true }"
      >
        <b-form-input
          id="input-1"
          ref="hoSoId"
          v-model="dataInput.hoSoId"
          v-trim-input
          type="text"
          disabled
          placeholder="Nhập mã hồ sơ"
          required
          @input="dataForm.hoSoId = dataInput.hoSoId"
        />
        <div class="label-noti-validate">
          {{ errors[0] }}
        </div>
        <div class="label-noti-validate">
          {{ errorTrungMa }}
        </div>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Phí hồ sơ (VNĐ)"
      label-for="phiInput"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="phiInput"
        :rules="{ required: true }"
        name="phiInput"
      >
        <b-form-input
          id="phiInput"
          ref="phiInput"
          v-model="dataInput.phiHoSo"
          :class="{required: errors.length > 0}"
          type="text"
          placeholder="Nhập phí hồ sơ"
          @input="dataForm.phiHoSo = dataInput.phiHoSo"
        />
        <div class="label-noti-validate">
          {{ errors[0] }}
        </div>
      </validation-provider>
    </b-form-group>

    <b-form-group
      id="input-group-1"
      label="Trạng thái phí hồ sơ"
      label-for="input-1"
    >
      <b-form-checkbox
        v-model="dataInput.phiHs_DaNop"
        switch
        @input="dataForm.phiHs_DaNop = dataInput.phiHs_DaNop"
      />
    </b-form-group>

    <b-form-group
      id="input-group-1"
      label="Lệ phí hồ sơ (VNĐ)"
      class="required"
      label-for="lePhiInput"
    >
      <validation-provider
        v-slot="{ errors }"
        key="lePhiInput"
        :rules="{ required: true }"
        name="lePhiInput"
      >
        <b-form-input
          id="lePhiInput"
          ref="lePhiInput"
          v-model="dataInput.lePhiHoSo"
          :class="{required: errors.length > 0}"
          type="text"
          placeholder="Nhập lệ phí hồ sơ"
          @input="dataForm.lePhiHoSo = dataInput.lePhiHoSo"
        />
        <div class="label-noti-validate">
          {{ errors[0] }}
        </div>
      </validation-provider>
    </b-form-group>

    <b-form-group
      id="input-group-1"
      label="Trạng thái lệ phí hồ sơ"
      label-for="input-1"
    >
      <b-form-checkbox
        v-model="dataInput.lePhi_DaNop"
        switch
        @input="dataForm.lePhi_DaNop = dataInput.lePhi_DaNop"
      />
    </b-form-group>

    <div v-if="formMode === 'NhanThuPhi'">
      <b-form-group
        id="input-group-1"
        label="Số điện thoại"
        label-for="soDienThoai"
        class="required"
      >
        <validation-provider
          v-slot="{ errors }"
          key="soDienThoai"
          :rules="'required|phone'"
          name="soDienThoai"
        >
          <b-form-input
            id="soDienThoai"
            ref="soDienThoai"
            v-model="dataInput.soDienThoai"
            v-block-e
            :class="{required: errors.length > 0}"
            type="number"
            placeholder="Nhập số điện thoại"
            @input="dataForm.soDienThoai = dataInput.soDienThoai"
          />
          <div class="text-danger">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </b-form-group>
    </div>
    <div v-else-if="formMode === 'ChoThuPhi'">
      <b-form-group
        id="input-group-1"
        label="Người nộp"
        label-for="nguoiNop"
        class="required"
      >
        <validation-provider
          v-slot="{ errors }"
          key="nguoiNop"
          :rules="{ required: true }"
          name="nguoiNop"
        >
          <b-form-input
            id="nguoiNop"
            ref="nguoiNop"
            v-model="dataInput.nguoiNop"
            v-trim-input
            type="text"
            :class="{required: errors.length > 0}"
            placeholder="Nhập người nộp"
            @input="dataForm.nguoiNop = dataInput.nguoiNop"
          />
          <div class="label-noti-validate">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </b-form-group>
    </div>

  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import $ from 'jquery'
import { extend } from 'vee-validate'
import { phone, required } from '@validations'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
    formMode: { type: String, default: 'NhanThuPhi' },
  },
  data() {
    return {
      phone,
      required,
      dataInput: {
        hoSoId: null,
        phiHoSo: null,
        lePhiHoSo: null,
        soDienThoai: null,
        phiHs_DaNop: true,
        lePhi_DaNop: true,
        nguoiNop: null,
      },
      errorTrungMa: null,
    }
  },
  created() {
    this.dataInput = this.dataForm
  },
  mounted() {
    this.$nextTick(() => {
      $('#phiInput').val(this.dataForm.phiHoSo)
      $('#lePhiInput').val(this.dataForm.lePhiHoSo)
    })
    $(document).ready(() => {
      // eslint-disable-next-line func-names
      $('#phiInput').on('keyup', function () {
        const str = $(this).val()
        const arr = str.split('')
        // eslint-disable-next-line prefer-const
        let out = []
        // eslint-disable-next-line no-plusplus
        for (let cnt = 0; cnt < arr.length; cnt++) {
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(arr[cnt]) === false) {
            out.push(arr[cnt])
          }
        }
        const num = Number(out.join(''))
        if (num === 0) {
          $(this).val('')
        } else {
          $(this).val(num.toLocaleString())
        }
      })
      // eslint-disable-next-line func-names
      $('#lePhiInput').on('keyup', function () {
        const str = $(this).val()
        const arr = str.split('')
        // eslint-disable-next-line prefer-const
        let out = []
        // eslint-disable-next-line no-plusplus
        for (let cnt = 0; cnt < arr.length; cnt++) {
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(arr[cnt]) === false) {
            out.push(arr[cnt])
          }
        }
        const num = Number(out.join(''))
        if (num === 0) {
          $(this).val('')
        } else {
          $(this).val(num.toLocaleString())
        }
      })
    })
  },
  methods: {
    getDataPhi(name) {
      return $(`#${name}`).val() || 0
    },
  },
}
</script>
<style scoped lang="scss">
// .input-type{
//   opacity : 0;
//   position : absolute;
//   top:0;
// }
</style>
